var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
        [
          _c("c-table", {
            ref: "apprline2",
            attrs: {
              title: "회람",
              tableId: "apprline2",
              topBorderClass: "topcolor-orange",
              columnSetting: false,
              isFullScreen: false,
              hideBottom: true,
              usePaging: false,
              filtering: false,
              gridHeight: "350px",
              columns: _vm.gridline2.columns,
              data: _vm.gridline2.data,
              isExcelDown: false,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
        [
          _c("c-table", {
            ref: "apprline",
            attrs: {
              title: "결재이력",
              tableId: "apprline",
              topBorderClass: "topcolor-orange",
              columnSetting: false,
              isFullScreen: false,
              hideBottom: true,
              usePaging: false,
              filtering: false,
              gridHeight: "350px",
              columns: _vm.gridline.columns,
              data: _vm.gridline.data,
              isExcelDown: false,
            },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props, col }) {
                  return [
                    col.name === "approvalKindName"
                      ? [
                          _c(
                            "span",
                            {
                              staticClass: "text-weight-bold",
                              class: _vm.getLineKindColor(
                                props.row.approvalKindCd
                              ),
                            },
                            [_vm._v(_vm._s(props.row.approvalKindName))]
                          ),
                        ]
                      : _vm._e(),
                    col.name === "approvalStatusName"
                      ? [
                          _c(
                            "span",
                            {
                              class: _vm.getLineKindColor2(
                                props.row.approvalStatusName
                              ),
                            },
                            [_vm._v(_vm._s(props.row.approvalStatusName))]
                          ),
                        ]
                      : _vm._e(),
                    col.name === "click"
                      ? [
                          _c(
                            "q-btn",
                            {
                              attrs: {
                                round: "",
                                unelevated: "",
                                size: "10px",
                                color: "amber",
                                icon: "search",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return (() => {
                                    ;(_vm.rowIndex = props.rowIndex),
                                      (_vm.electronSignature =
                                        props.row.electronSignature)
                                  }).apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c(
                                "q-popup-proxy",
                                {
                                  ref: "proxy1_" + props.rowIndex,
                                  attrs: { breakpoint: 400 },
                                },
                                [
                                  _c(col.component, {
                                    tag: "component",
                                    attrs: {
                                      popupParam: props.row,
                                      rowIndex: props.rowIndex,
                                    },
                                    on: { callback: _vm.callback1 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }